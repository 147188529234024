import { addErrorHandler, registerApplication, start } from 'single-spa';
import {
	constructApplications,
	constructRoutes,
	constructLayoutEngine,
} from 'single-spa-layout';
import './layout.css';
import microfrontendLayout from './microfrontend-layout.html';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { hideLoader, showLoader } from './functions/load-screen';

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
	routes,
	loadApp({ name }) {
		return System.import(name);
	},
});
const layoutEngine = constructLayoutEngine({
	routes,
	applications,
	active: false,
});

applications.forEach(registerApplication);

// Show loader on application is loading
// window.addEventListener('single-spa:before-no-app-change', showLoader);
// window.addEventListener('single-spa:before-mount-routing-event', showLoader);
// window.addEventListener('single-spa:before-routing-event', showLoader);

// Hide loader when the apps are currently mounted
// window.addEventListener('single-spa:first-mount', hideLoader);
window.addEventListener('single-spa:routing-event', hideLoader);

// Handle application error load
addErrorHandler((err) => {
	console.error(err);
	hideLoader();
});

System.import('@viuti/recursos').then(() => {
	// Activate the layout engine once the styleguide CSS is loaded
	layoutEngine.activate();
	start();
	serviceWorkerRegistration.register({
		onUpdate: () => {
			console.log('Aplicacion actualizada.');
		},
		onSuccess: () => {
			console.log('Contenido almacenado en cache para uso sin conexion.');
		},
	});
});
