let width = 0;
let interval;
let speed = 10;
let isPaused = false;

export function startProgress() {
  clearInterval(interval);
  width = 0;
  speed = 10; // Initial speed
  isPaused = false;
  interval = setInterval(frame, speed);

  function frame() {
    if (width >= 95) {
      // Limit the progress to 95%
      clearInterval(interval);
    } else if (!isPaused) {
      width += 0.5; // Increment by 0.5%
      document.getElementById("progressBar").style.width = width + "%";

      speed = speed * (1 + Math.random() * 0.04); // Increase the interval time to slow down the progress randomly between 1 and 1.04
      clearInterval(interval);
      interval = setInterval(frame, speed);

      // Randomly decide to pause
      if (Math.random() < 0.05) {
        // 5% chance to pause
        isPaused = true;
        setTimeout(() => {
          isPaused = false;
        }, 500 + Math.random() * 1000); // Pause for 500 to 1500 ms
      }
    }
  }
}

export function completeProgress() {
  const loader = document.getElementById("loader");
  clearInterval(interval);
  setTimeout(() => {
    width = 100;
    document.getElementById("progressBar").style.width = width + "%";
    setTimeout(() => {
      loader.classList.add("hidden");
      document.getElementById("progressBar").style.width = "0%";
    }, 1000); // Hide the loader after 1 second
  }, 500);
}
