import { startProgress, completeProgress } from './progress-bar';

// Manage the application loading state
export const showLoader = () => {
	const loader = document.getElementById('loader');
	if (loader) {
		loader.classList.remove('hidden');
		startProgress();
	}
};

export const hideLoader = () => {
	const loader = document.getElementById('loader');
	if (loader) {
		completeProgress();
	}
};
