// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	z-index: 9999;
	opacity: 1;
	visibility: visible;
	transition: opacity 300ms ease, visibility 300ms ease;

	&.hidden {
		opacity: 0;
		visibility: hidden;
	}
}

.loader_logo {
	margin-top: -80px;
	width: 10rem;
	height: auto;

	& > img {
		width: 100%;
		height: 100%;
	}
}

#progressContainer {
	width: 12rem;
	background-color: #ddd;
	height: 5px;
	border-radius: 5px;
}

#progressBar {
	width: 0%;
	height: 100%;
	background-color: #45348e;
	text-align: center;
	line-height: 2px;
	color: white;
	border-radius: 5px;
	transition: all 1000ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
`, "",{"version":3,"sources":["webpack://./src/layout.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,MAAM;CACN,OAAO;CACP,WAAW;CACX,YAAY;CACZ,mBAAmB;CACnB,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,mBAAmB;CACnB,iBAAiB;CACjB,aAAa;CACb,UAAU;CACV,mBAAmB;CACnB,qDAAqD;;CAErD;EACC,UAAU;EACV,kBAAkB;CACnB;AACD;;AAEA;CACC,iBAAiB;CACjB,YAAY;CACZ,YAAY;;CAEZ;EACC,WAAW;EACX,YAAY;CACb;AACD;;AAEA;CACC,YAAY;CACZ,sBAAsB;CACtB,WAAW;CACX,kBAAkB;AACnB;;AAEA;CACC,SAAS;CACT,YAAY;CACZ,yBAAyB;CACzB,kBAAkB;CAClB,gBAAgB;CAChB,YAAY;CACZ,kBAAkB;CAClB,0DAA0D;AAC3D","sourcesContent":["#loader {\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n\tbackground: #ffffff;\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\tfont-size: 1.5rem;\n\tz-index: 9999;\n\topacity: 1;\n\tvisibility: visible;\n\ttransition: opacity 300ms ease, visibility 300ms ease;\n\n\t&.hidden {\n\t\topacity: 0;\n\t\tvisibility: hidden;\n\t}\n}\n\n.loader_logo {\n\tmargin-top: -80px;\n\twidth: 10rem;\n\theight: auto;\n\n\t& > img {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t}\n}\n\n#progressContainer {\n\twidth: 12rem;\n\tbackground-color: #ddd;\n\theight: 5px;\n\tborder-radius: 5px;\n}\n\n#progressBar {\n\twidth: 0%;\n\theight: 100%;\n\tbackground-color: #45348e;\n\ttext-align: center;\n\tline-height: 2px;\n\tcolor: white;\n\tborder-radius: 5px;\n\ttransition: all 1000ms cubic-bezier(0.075, 0.82, 0.165, 1);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
